var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-danger",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "StopCircleIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Poin Anda")])]), _c('h2', [_c('strong', [_vm._v(_vm._s(_vm.rekapPoin.dapat_ditarik))])])])])]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('section', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push('/riwayat-reward');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "FileTextIcon"
    }
  }), _vm._v(" Riwayat ")], 1)], 1)])], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, _vm._l(_vm.rewards, function (reward) {
    return _c('reward-card', {
      key: reward.id,
      attrs: {
        "rekapPoin": _vm.rekapPoin,
        "reward": reward,
        "onRedeem": _vm.redeemReward
      },
      on: {
        "loadData": _vm.loadData
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }