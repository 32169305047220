<template>
  <div id="app">
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <b-card>
          <b-row>
            <b-col md="10">
              <div class="d-flex align-items-center justify-content-start">
                <section class="icon">
                  <b-avatar :variant="`light-danger`" size="50">
                    <feather-icon size="24" icon="StopCircleIcon" />
                  </b-avatar>
                </section>
                <section class="info ml-2">
                  <strong class="d-block" style="margin-bottom: 5px"><small>Poin Anda</small></strong>
                  <h2>
                    <strong>{{ rekapPoin.dapat_ditarik }}</strong>
                  </h2>
                </section>
              </div>
            </b-col>
            <b-col md="2">
              <section class="d-flex align-items-center mt-1">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click.prevent="$router.push('/riwayat-reward')">
                  <feather-icon icon="FileTextIcon" class="mr-50" />
                  Riwayat
                </b-button>
              </section>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <reward-card v-for="reward in rewards" :key="reward.id" :rekapPoin="rekapPoin" :reward="reward" :onRedeem="redeemReward" @loadData="loadData" />
    </b-overlay>
  </div>
</template>

<script>
import RewardCard from "./components/RewardCard.vue";
import { BImg, BAvatar, BRow, BCol, BCard, BCardBody, BButton, BOverlay } from "bootstrap-vue";

export default {
  components: {
    RewardCard,
    BImg,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BOverlay,
  },
  data() {
    return {
      rewards: [],
      rekapPoin: null,
      totalRows: 0,
      loading: false,
    };
  },
  methods: {
    redeemReward(reward) {
      // Handle reward redemption logic (e.g., deduct points, update database)
      console.log(`Anda me-redeem ${reward.name}`);
    },
    loadData() {
      this.getData();
      this.getRekapDataPoin();
    },
    getData() {
      this.loading = true;
      this.$store
        .dispatch("reward/getData", {})
        .then(() => {
          this.loading = false;
          this.rewards = this.$store.state.reward.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
    getRekapDataPoin() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("poin/getRekap", payload)
        .then((res) => {
          this.rekapPoin = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
  },
  created() {
    this.getData();
    this.getRekapDataPoin();
  },
};
</script>

<style>
/* Add global styling as needed */
</style>
